var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.execute),expression:"execute"}],staticClass:"cursor-pointer w-auto",attrs:{"id":"sale-menu"}},[_c('i',{staticClass:"mdi mdi-menu-down-outline mdi-18px",on:{"click":_vm.menuList}}),_c('ul',{ref:"menu",staticClass:"bg-white list no-bullets position-absolute px-3 py-2 shadow z-index-999"},[(
      (
        _vm.role === 'Admin' ||
        _vm.role === 'Branch Admin' ||
        _vm.role === 'Branch Admin assistant' ||
        _vm.role === 'Customer assistant'
      )
      && (_vm.currentStatus === 2 || _vm.currentStatus === 5 || _vm.currentStatus === 6 || _vm.currentStatus === 7 || _vm.currentStatus === 8 || _vm.currentStatus === 9)
      )?_c('li',{staticClass:"text-sm mb-1 py-1",on:{"click":function($event){return _vm.$emit('win')}}},[_c('i',{staticClass:"mdi mdi-account-check mr-2"}),_c('span',{staticClass:"text-gray-600"},[_vm._v("ได้งาน")])]):_vm._e(),(
      (
        _vm.role === 'Admin' ||
        _vm.role === 'Branch Admin' ||
        _vm.role === 'Branch Admin assistant' ||
        _vm.role === 'Customer assistant'
      )
      && (_vm.currentStatus === 2 || _vm.currentStatus === 5 || _vm.currentStatus === 6 || _vm.currentStatus === 7 || _vm.currentStatus === 8 || _vm.currentStatus === 9)
      )?_c('li',{staticClass:"text-sm mb-1 py-1",on:{"click":function($event){return _vm.$emit('compare')}}},[_c('i',{staticClass:"mdi mdi-file-pdf mr-2"}),_c('span',{staticClass:"text-gray-600"},[_vm._v("เทียบราคา")])]):_vm._e(),(
      (
        _vm.role === 'Admin' ||
        _vm.role === 'Branch Admin' ||
        _vm.role === 'Branch Admin assistant' ||
        _vm.role === 'Customer assistant'
      )
      && (_vm.currentStatus === 2 || _vm.currentStatus === 5 || _vm.currentStatus === 6 || _vm.currentStatus === 7 || _vm.currentStatus === 8 || _vm.currentStatus === 9)
      )?_c('li',{staticClass:"text-sm mb-1 py-1",on:{"click":function($event){return _vm.$emit('claque')}}},[_c('i',{staticClass:"mdi mdi-account-alert mr-2"}),_c('span',{staticClass:"text-gray-600"},[_vm._v("คู่เทียบ")])]):_vm._e(),(
      (
        _vm.role === 'Admin' ||
        _vm.role === 'Branch Admin' ||
        _vm.role === 'Branch Admin assistant' ||
        _vm.role === 'Customer assistant'
      )
      && (_vm.currentStatus === 2 || _vm.currentStatus === 5 || _vm.currentStatus === 6 || _vm.currentStatus === 7 || _vm.currentStatus === 8 || _vm.currentStatus === 9)
      )?_c('li',{staticClass:"text-sm mb-1 py-1",on:{"click":function($event){return _vm.$emit('lose')}}},[_c('i',{staticClass:"mdi mdi-window-close mr-2"}),_c('span',{staticClass:"text-gray-600"},[_vm._v("ไม่ได้งาน")])]):_vm._e(),((
        _vm.role === 'Admin' ||
        _vm.role === 'Branch Admin' ||
        _vm.role === 'Branch Admin assistant' ||
        _vm.role === 'Account manager' ||
        _vm.role === 'Assistant account manager' ||
        _vm.role === 'Sales manager' ||
        _vm.role === 'Assistant sales manager' ||
        _vm.role === 'Account executive' ||
        _vm.role === 'Customer assistant'
        )
        && 
        (
          (_vm.currentStatus !== 3 && _vm.currentStatus !== 4)
        )
      )?_c('li',{staticClass:"text-sm mb-1 py-1",on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('i',{staticClass:"mdi mdi-cancel mr-2"}),_c('span',{staticClass:"text-gray-600"},[_vm._v("ยกเลิกใบเสนอราคา")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }